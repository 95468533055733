import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../views/privacy-policy"),
  },
  {
    path: "/apply",
    name: "Apply",
    component: () => import("../views/apply/index.vue"),
  },
  {
    path: "/applyToiletries",
    name: "applyToiletries",
    component: () => import("../views/applyToiletries/applyToiletries.vue"),
  },
  {
    path: "/applyToiletriesHotel",
    name: "applyToiletriesHotel",
    component: () => import("../views/applyToiletries/applyToiletriesHotel.vue"),
  },
  {
    path: "/applyed",
    name: "Applyed",
    component: () => import("../views/apply/success.vue"),
  },
  {
    path: "/maintain",
    name: "maintain",
    component: () => import("../views/maintain"),
  },
  {
    path: "/ningbo",
    name: "ningbo",
    component: () => import("../views/ningbo"),
  },
  {
    path: "/pdf",
    name: "pdf",
    component: () => import("../views/pdf"),
  },
  {
    path: "/ningboaa",
    name: "ningboaa",
    component: () => import("../views/ningbocopy"),
  },
  {
    path: "/assessment",
    name: "assessment",
    component: () => import("../views/assessment"),
  },
  {
    path: "/testLocation",
    name: "testLocation",
    component: () => import("../views/testLocation"),
  },
  
];

const router = new VueRouter({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

export default router;
